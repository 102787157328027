<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-row
      no-gutters
      class="pb-4"
    >
      <v-col cols="auto">
        <v-btn
          class="mr-2"
          color="secondary"
        >
          Все
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="actions-bar"
      >
        <v-btn
          color="green"
          @click="createDialog"
        >
          Добавить поставщика
        </v-btn>
      </v-col>
    </v-row>

    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Поставщики</div>

        <div class="text-subtitle-1 font-weight-light">
          <h5>Общее количество: {{ suppliersList.length }}</h5>
        </div>
      </template>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="suppliersList"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editDialog(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              color="red"
              @click="deleteDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Поставщик</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              v-model="valid"
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.name"
                    label="Имя"
                    :error-messages="errors.name"
                    @input="errors.name = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.city_id"
                    :items="citiesList"
                    item-text="name"
                    item-value="id"
                    label="Город"
                    :error-messages="errors.city_id"
                    @change="errors.city_id = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.director_name"
                    label="Имя директора"
                    :error-messages="errors.director_name"
                    @input="errors.director_name = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.phone"
                    label="Телефон"
                    :error-messages="errors.phone"
                    @input="errors.phone = []"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>
          <v-btn
            v-if="actionMethod === 'edit'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="editSupplier"
          >
            Редактировать
          </v-btn>
          <v-btn
            v-if="actionMethod === 'create'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="createSupplier"
          >
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">Вы действительно хотите удалить?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
          >
            Отменить
          </v-btn>
          <v-btn
            color="danger darken-1"
            text
            @click="deleteSupplier"
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import SuppliersService from '@/app/Services/SuppliersService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'
import GetSuppliers from '@/app/Helpers/mixins/essences/getSuppliers'

export default {
  name: 'SuppliersDashboard',

  mixins: [TableDialogMixin, GetSuppliers],

  data() {
    return {
      headers: [
        {
          sortable: false,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Имя',
          value: 'name',
        },
        {
          sortable: false,
          text: 'Город',
          value: 'city.name',
          align: 'right',
        },
        {
          sortable: false,
          text: 'Имя директора',
          value: 'director_name',
          align: 'right',
        },
        {
          sortable: false,
          text: 'Телефон',
          value: 'phone',
          align: 'right',
        },
        {
          sortable: false,
          text: 'Действия',
          value: 'actions',
        },
      ],
      dialogData: {
        name: null,
        city_id: null,
        director_name: null,
        phone: null,
      },
    }
  },

  computed: {
    citiesList() {
      return this.$store.state.references.cities
    },
  },

  async created() {
    this.loading = true

    await this.getSuppliers()

    this.loading = false
  },

  methods: {
    async createSupplier() {
      if (this.$refs.form.validate()) {
        const response = await SuppliersService.createSupplier(this.dialogData)

        if (response.status) {
          await this.getSuppliers()
          this.dialog = false
        } else {
          this.errors = response.error
        }
      }
    },

    async editSupplier() {
      if (this.$refs.form.validate()) {
        const response = await SuppliersService.editSupplier(this.selectedId, this.dialogData)

        if (response.status) {
          await this.getSuppliers()
          this.dialog = false
        } else {
          this.errors = response.error
        }
      }
    },

    async deleteSupplier() {
      const response = await SuppliersService.deleteSupplier(this.selectedId)

      if (response.status) {
        await this.getSuppliers()
        this.dialogDelete = false
      } else {
        this.errors = response.error
      }
    },
  },
}
</script>
