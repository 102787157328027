<script>
import SuppliersService from '@/app/Services/SuppliersService'

export default {
  data() {
    return {
      suppliersList: [],
    }
  },

  methods: {
    async getSuppliers() {
      const response = await SuppliersService.getSuppliers()

      if (response.status) {
        this.suppliersList = response.data

        this.$store.dispatch('suppliers/setSuppliersData', response.data)
      }
    },
  },
}
</script>
