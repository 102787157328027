import api from '@/app/api'

export default {
  async getSuppliers() {
    const response = await api.get('/supplier')
    return response
  },

  async createSupplier(supplierData) {
    const response = await api.post('/supplier', supplierData)
    return response
  },

  async getSupplierById(id) {
    const response = await api.get(`/supplier/${id}`)
    return response
  },

  async editSupplier(id, supplierData) {
    const response = await api.put(`/supplier/${id}`, supplierData)
    return response
  },

  async deleteSupplier(id) {
    const response = await api.delete(`/supplier/${id}`)
    return response
  },
}
